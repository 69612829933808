export interface ConfigType {
  get(path: string, defaultValue?: any): any;
  env(key: string, defaultValue?: any): any;
}

export enum Envs {
  DEV = 'development',
  PROD = 'production',
  TEST = 'test',
  DEFAULT = 'default',
}

export type Settings<T = {}> = {
  [K in keyof T]: { [K in keyof T]: { [P in Envs]?: T[K] } };
};

export type EnhanchedConfig = ConfigType & Settings;
