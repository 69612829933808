import get_ from 'lodash.get';
import merge from 'deepmerge';

import { isObj, enhance } from './utils';
import { Settings, Envs, ConfigType } from './types';

class Config implements ConfigType {
  private readonly data: object;

  constructor(data: Settings = {}) {
    this.data = this.parse(data);

    Object.entries(this.data).forEach(([key, value]) =>
      enhance<this>(this, key, value)
    );
  }

  get(path: string, defaultValue?: any): any {
    return get_(this.data, path, defaultValue);
  }

  env(key: string, defaultValue?: any): any {
    return Object.prototype.hasOwnProperty.call(process.env, key)
      ? process.env[key]
      : defaultValue;
  }

  private parse(data): object {
    const env = this.env('NODE_ENV');

    return Object.entries(data).reduce((a, [key, value]) => {
      if (Object.prototype.hasOwnProperty.call(value, env)) {
        if (isObj(value[this.env('NODE_ENV')])) {
          a[key] = merge(value[Envs.DEFAULT], value[env], {
            arrayMerge: (target, source) => source,
          });
        } else {
          a[key] = value[env];
        }
      } else if (Object.prototype.hasOwnProperty.call(value, Envs.DEFAULT)) {
        a[key] = value[Envs.DEFAULT];
      } else {
        a[key] = value;
      }

      return a;
    }, {});
  }
}

export default Config;
