import React, { createContext } from 'react';
import Config from './Config';
import { EnhanchedConfig } from './types';

export interface ConfigProviderProps {
  config: EnhanchedConfig;
}

export const ConfigContext = createContext<EnhanchedConfig>(new Config());

export type ConfigProviderComponent = React.FC<ConfigProviderProps>;

const ConfigProvider: ConfigProviderComponent = ({ children, config }) => {
  return (
    <ConfigContext.Provider value={config}>{children}</ConfigContext.Provider>
  );
};

export default ConfigProvider;
