export const isObj = (a: any): boolean => {
  const obj = Object(a);
  return obj.toString() === '[object Object]';
};

export const isFn = (value: any): boolean => typeof value === 'function';

export const enhance = <T>(instance: T, name: string, value: any): void => {
  if (value && (isFn(value.getter) || isFn(value.setter))) {
    Object.defineProperty(instance, name, {
      get: value.getter,
      set: value.setter,
    });
  } else {
    instance[name] = value;
  }
};
